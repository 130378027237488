import { Component } from '@/types/component';
import { StandaloneEdge, StandaloneEdgeProps } from 'base/components/Edge';
import { cn } from 'lib/utils';
import localFonts from 'next/font/local';

const fontPrimary = localFonts({
  src: [
    { path: '../../../public/fonts/CarneroRegular_normal_normal_subset1.woff2', weight: '400', style: 'normal' },
    { path: '../../../public/fonts/CarneroBold_normal_normal_subset1.woff2', weight: '700', style: 'normal' },
    { path: '../../../public/fonts/CarneroItalic_italic_normal_subset1.woff2', weight: '400', style: 'italic' },
  ],
  display: 'swap',
  preload: true,
  variable: '--font-primary',
});

const fontSecondary = localFonts({
  src: [
    { path: '../../../public/fonts/ITCCharterRegular_normal_normal_subset1.woff2', weight: '400', style: 'normal' },
    { path: '../../../public/fonts/ITCCharterBold_normal_normal_subset1.woff2', weight: '700', style: 'normal' },
    {
      path: '../../../public/fonts/ITCCharterRegularItalic_italic_normal_subset1.woff2',
      weight: '400',
      style: 'italic',
    },
  ],
  display: 'swap',
  preload: false,
  variable: '--font-secondary',
});

const fonts = cn(fontPrimary.variable, fontSecondary.variable);

export const MabraStandaloneEdge: Component<StandaloneEdgeProps> = ({ children }) => {
  return <StandaloneEdge className={fonts}>{children}</StandaloneEdge>;
};
