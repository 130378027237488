/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { MabraStandaloneStateProviders as ResolvedStandaloneStateProviders } from 'sites/mabra/components/standalone/StateProviders';

export const StateProviders = ResolvedStandaloneStateProviders;

export type StateProvidersProps = PropsFromComponent<typeof StateProviders>;

export const StateProvidersWith = (extras: DynamicStandaloneExtras): typeof StateProviders => {
    return function StateProviders(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'StateProviders');
        return <ResolvedStandaloneStateProviders {...mergeProps({ options: { theme } }, props)} />;
    }
}